@import url('https://fonts.cdnfonts.com/css/sf-pro-display?styles=98774,98777,98776,98772,98773,98775,98770,98771,98769');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SF Pro Display',"inter";
  scroll-behavior: smooth;
}


.container {
  width: 100%;
}

.linier2{
  background: linear-gradient(90deg, #656FFF 0%, #040A61 111.15%);
}
.linier{
  background: linear-gradient(90deg, rgba(0, 14, 208, 0.3) 0%, rgba(217, 217, 217, 0) 13.43%);
}
.linier3{
  background: linear-gradient(90deg, #3C86DC 0%, #003F89 100%);
}

.linier4{
  background: linear-gradient(103.08deg, #E5E7FA 0%, #FEE2E2 100%);
}
